<template>
  <main class="wrapper">
    <h3 class="uk-h2">Income Replacement Calculator</h3>
    <form @submit.prevent="handleSubmit">
      <div class="form-wrapper">

        <div class="field">

          <div class="label">
            <label>Your Current Age</label>
            <!-- <div class="information" tooltip="How old are you right now." tooltip-position="left">?</div> -->
            <input @change="ageLimit" type="number" v-model="age" />
          </div>
          
          <input @change="desiredRetirementAgeAdjustment" type="range" :min="minAge" :max="maxAge" required v-model="age">
          <div class="range-values"><span>{{minAge}}</span><span>{{maxAge}}</span></div>
        </div>

        <div class="field">
          <div class="label">
            <label>Desired Retirement Age</label>
            <!-- <div class="information" tooltip="How old do you want to be when you retire." tooltip-position="left">?</div> -->
            <input @change="desiredRetirementAgeLimit" type="number" v-model="desiredRetirementAge" />
          </div>
          
          <input type="range" :min="minDesiredRetirementAge" :max="maxDesiredRetirementAge" required v-model="desiredRetirementAge">
          <div class="range-values"><span>{{minDesiredRetirementAge}}</span><span>{{maxDesiredRetirementAge}}</span></div>
        </div>

        <div class="field">
          <div class="label">
            <label>Current Cash <div class="information" tooltip="Money sitting in your bank that is not invested. Use your combined current cash if you are a couple." tooltip-position="left">?</div> <small>that isn't invested</small></label>
            
            <div class="currency-input">
              <input @change="cashLimit" type="number" v-model="cash" />
              <span class="currency-symbol">$</span>
            </div>
          </div>
          
          <input type="range" :min="minCash" :max="maxCash" :step="moneyStep" required v-model="cash">
          <div class="range-values"><span>{{currency_format(minCash,0)}}</span><span>{{ currency_format(maxCash,0) }}</span></div>
        </div>

        <div class="field">
          <div class="label">
            <label>Annual Income <div class="information" tooltip="Use your combined annual income if you are a couple." tooltip-position="left">?</div> <small>exc. Passive income</small></label>
            <div class="currency-input">
              <input @change="annualIncomeLimit" type="number" v-model="annualIncome" />
              <span class="currency-symbol">$</span>
            </div>
          </div>
            
          <input type="range" :min="minAnnualIncome" :max="maxAnnualIncome" :step="moneyStep" required v-model="annualIncome">
          <div class="range-values"><span>{{currency_format(minAnnualIncome,0)}}</span><span>{{currency_format(maxAnnualIncome,0)}}</span></div>
        </div>

        <div class="field">
          <div class="label">
            <label>Annual Passive Income  <div class="information" tooltip="Use your combined annual passive income if you are a couple." tooltip-position="left">?</div></label>
            <div class="currency-input">
              <input @change="annualPassiveIncomeLimit" type="number" v-model="annualPassiveIncome" />
              <span class="currency-symbol">$</span>
            </div>
          </div>
            
          <input type="range" :min="minAnnualPassiveIncome" :max="maxAnnualPassiveIncome" :step="moneyStep" required v-model="annualPassiveIncome">
          <div class="range-values"><span>{{currency_format(minAnnualPassiveIncome,0)}}</span><span>{{currency_format(maxAnnualPassiveIncome,0)}}</span></div>
        </div>

        <div class="field">
          <div class="label">
            <label>Annual Expenses  <div class="information" tooltip="Use your combined annual expenses if you are a couple." tooltip-position="left">?</div> <small>exc. Mortgage</small></label>
            <div class="currency-input">
              <input @change="annualExpensesLimit" type="number" v-model="annualExpenses" />
              <span class="currency-symbol">$</span>
            </div>
          </div>
            
          <input type="range" :min="minAnnualExpenses" :max="maxAnnualExpenses" :step="moneyStep" required v-model="annualExpenses">
          <div class="range-values"><span>{{currency_format(minAnnualExpenses,0)}}</span><span>{{currency_format(maxAnnualExpenses,0)}}</span></div>
        </div>

        <div class="field">
          <div class="label">
            <label>Annual Investment Return</label>
            <!-- <div class="information" tooltip="How much are your investments making per year in average. If you don't know, a safe 7% our suggested default option." tooltip-position="left">?</div> -->
            <input @change="annualInvestmentRateLimit" type="number" :step="rateStep" v-model="annualInvestmentRate" />
          </div>
          
          <input type="range" :min="minAnnualInvestmentRate" :max="maxAnnualInvestmentRate" :step="rateStep" required v-model="annualInvestmentRate">
          <div class="range-values"><span>{{minAnnualInvestmentRate}}</span><span>{{maxAnnualInvestmentRate}}</span></div>
        </div>

        <div class="field btm-margin">
          <div class="label">
            <label>Do you have a mortgage?</label>
            <label class="switch">
              <input type="checkbox" v-model="mortgage">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="field" v-if="mortgage">

          <div class="label">
            <label>Mortgage Years Left</label>
            <!-- <div class="information" tooltip="How old are you right now." tooltip-position="left">?</div> -->
            <input @change="mortgageYearsLeftLimit" type="number" v-model="mortgageYearsLeft" />
          </div>
          
          <input type="range" :min="minMortgageYearsLeft" :max="maxMortgageYearsLeft" required v-model="mortgageYearsLeft">
          <div class="range-values"><span>{{minMortgageYearsLeft}}</span><span>{{maxMortgageYearsLeft}}</span></div>
        </div>

        <div class="field" v-if="mortgage">

          <div class="label">
            <label>Mortgage Payment Frequency</label>
          </div>
          
          <select required v-model="mortgagePaymentFrequency">
            <option value="52">Weekly</option>
            <option value="26">Fortnightly</option>
            <option value="12">Monthly</option>
            <option value="1">Annually</option>
          </select>
        </div>

        <div class="field" v-if="mortgage">

          <div class="label">
            <label>Loan Repayments</label>
            <!-- <div class="information" tooltip="How old are you right now." tooltip-position="left">?</div> -->
            <input @change="mortgagePaymentLimit" type="number" v-model="mortgagePayment" />
          </div>
          
          <input type="range" :min="minMortgagePayment" :max="maxMortgagePayment" :step="moneyStep" required v-model="mortgagePayment">
          <div class="range-values"><span>{{currency_format(minMortgagePayment,0)}}</span><span>{{currency_format(maxMortgagePayment,0)}}</span></div>
        </div>

        <div class="field btm-margin">
          <div class="label">
            <label>Include Your Retirement Fund? <div class="information" tooltip="The Retirement Fund may be called differntly in your country. e.g. Superannuation in Australia or 401(k) in the US." tooltip-position="left">?</div></label>
            <label class="switch">
              <input type="checkbox" v-model="pension">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="field" v-if="pension">
          <div class="label">
            <label>Retirement Fund Access Age <div class="information" tooltip="How old will you be when you can access your retirement fund." tooltip-position="left">?</div></label>
            <input  @change="pensionAgeLimit" type="number" v-model="pensionAge" />
          </div>
          
          <input type="range" :min="minPensionAge" :max="maxPensionAge" required v-model="pensionAge">
          <div class="range-values"><span>{{minPensionAge}}</span><span>{{maxPensionAge}}</span></div>
        </div>

        <div class="field" v-if="pension">
          <div class="label">
            <label>Retirement Fund Annual Income <div class="information" tooltip="How much are you expecting to receive every year from your retirement fund." tooltip-position="left">?</div></label>
            <div class="currency-input">
              <input @change="pensionIncomeLimit" type="number" v-model="pensionIncome" />
              <span class="currency-symbol">$</span>
            </div>
          </div>
            
          <input type="range" :min="minPensionIncome" :max="maxPensionIncome" :step="moneyStep" required v-model="pensionIncome">
          <div class="range-values"><span>{{currency_format(minPensionIncome,0)}}</span><span>{{currency_format(maxPensionIncome,0)}}</span></div>
        </div>

        <div class="field btm-margin" v-if="pension">
          <div class="label">
            <label>Include Partner's Retirement Fund?</label>
            <label class="switch">
              <input type="checkbox" v-model="partnerPension">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="field" v-if="partnerPension && pension">

          <div class="label">
            <label>Partner's Age</label>
            <!-- <div class="information" tooltip="How old are you right now." tooltip-position="left">?</div> -->
            <input @change="partnerAgeLimit" type="number" v-model="partnerAge" />
          </div>
          
          <input type="range" :min="minPartnerAge" :max="maxPartnerAge" required v-model="partnerAge">
          <div class="range-values"><span>{{minPartnerAge}}</span><span>{{maxPartnerAge}}</span></div>
        </div>

        <div class="field" v-if="partnerPension && pension">
          <div class="label">
            <label>Partner's Fund Access Age <div class="information" tooltip="How old will your partner be when they can access their retirement fund." tooltip-position="left">?</div></label>
            <input  @change="partnerPensionAgeLimit" type="number" v-model="partnerPensionAge" />
          </div>
          
          <input type="range" :min="minPensionAge" :max="maxPensionAge" required v-model="partnerPensionAge">
          <div class="range-values"><span>{{minPensionAge}}</span><span>{{maxPensionAge}}</span></div>
        </div>

        <div class="field" v-if="partnerPension && pension">
          <div class="label">
            <label>Partner's Fund Annual Income <div class="information" tooltip="How much is your partner expecting to receive every year from their retirement fund." tooltip-position="left">?</div></label>
            <div class="currency-input">
              <input @change="partnerPensionIncomeLimit" type="number" v-model="partnerPensionIncome" />
              <span class="currency-symbol">$</span>
            </div>
          </div>
            
          <input type="range" :min="minPensionIncome" :max="maxPensionIncome" :step="moneyStep" required v-model="partnerPensionIncome">
          <div class="range-values"><span>{{currency_format(minPensionIncome,0)}}</span><span>{{currency_format(maxPensionIncome,0)}}</span></div>
        </div>

      </div>

      
      <div class="submit">
        <button>Calculate</button>
      </div>
      
    </form>

    <div id="disclaimer-modal" class="modal">
      <div class="modal__content">
        <div class="uk-h4">Disclaimer</div>
        <ul>
          <li>Calculations are estimates and provided for illustrative purposes only.</li>
          <li>The information produced by the calculator is not an indication of nor is it intended to predict future outcomes.</li>
          <li>The calculator is not intended to be relied on for the purposes of making a decision in relation to a financial product and that you should consider obtaining advice from a financial services licensee before making any financial decisions.</li>
          <li>The calculator utilises information supplied by the user and assumptions made by the calculator to provide a guide only.</li>
          <li>The calculator doesn't consider inflation and it assumes the values remain constant for years, which is a scenario that doesn't match the real world.</li>
        </ul>
        <a href="#" class="modal__close">&times;</a>
      </div>
    </div>
    
    <div class="results-wrapper" v-if="formSent">
      <div class="results-inner-wrapper">
        <div v-if="!tooMuchExpenses" class="results-text">
          <h2>Results</h2>
          <template v-if="isDoable==='yes'">
            <p>That's great, you'll be able to retire in {{ yearsToRetire }} year(s).</p>
          </template>
          <template v-if="isDoable==='no'">
            <p>You will need to make some adjustments to achieve your goal, here are the options:</p>
            <ul class="results">
              <li v-if="!blownUpInvestmentRate" class="moneyIcon">Find an investment vehicle that earns: {{ idealInvestmentRate }}%</li>
              <li v-if="!blownUpIdealExpenses" class="teethIcon">Decrease your expenses to {{ currency_format(idealExpenses,0) }}</li>
              <li v-if="!blownUpRetirementAge" class="oldPersonIcon">Retire at {{ idealRetirementAge }}</li>
              <li v-if="!blownUpIdealIncome" class="monocleIcon">Increase your income to {{ currency_format(idealIncome,0) }}</li>
              <li v-if="blownUpIdealIncome && blownUpInvestmentRate && blownUpIdealExpenses && blownUpRetirementAge" class="screaming">Our algorithm couldn't find any specific adjustments. These numbers are not looking good. Decrease your expenses, increase your income and try again.</li>
            </ul>
          </template>
          <p><a href="#disclaimer-modal">Disclaimer</a></p>
        </div>
        <div v-else>
          <p>Your total expenses {{ currency_format(totalExpenses,0) }} are more than your income {{ currency_format(totalIncome,0) }}, start by reducing your expenses or increasing your income so you earn more than you spend.</p>
          <p>Also, make sure you didn't add your mortgage repayments in the expenses field, that field excludes mortgage because mortgage has its own field.</p>
          <a href="#disclaimer-modal">Disclaimer</a>
        </div>
        <div v-if="!tooMuchExpenses && !blownUpRetirementAge" class="graph">
              <apexchart
                ref="chart"
                width="100%"
                type="line"
                :options="chartOptions"
                :series="chartSeries"
              ></apexchart>
        </div>
      </div>
    </div>
  </main>
  <footer>
      <a href="https://koalawidgets.com/" target="_blank"><img src="@/assets/badge-final-400.png" alt="Powered By Koala Widgets" class="badge" /></a>
    </footer>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'App',
  components: {
    apexchart: VueApexCharts,
  },
  data() {

      return {
          age: '35',
          maxAge: '60',
          minAge: '12',

          desiredRetirementAge: '50',
          maxDesiredRetirementAge: '65',
          minDesiredRetirementAge: '13',
          blownUpRetirementAge: false,

          cash: 10000,
          maxCash: 1000000,
          minCash: 0,

          moneyStep: 1000,

          annualIncome: 100000,
          maxAnnualIncome: 500000,
          minAnnualIncome: 0,
          blownUpIdealIncome: false,

          annualPassiveIncome: 7000,
          maxAnnualPassiveIncome: 1000000,
          minAnnualPassiveIncome: 0,

          annualExpenses: 70000,
          maxAnnualExpenses: 500000,
          minAnnualExpenses: 0,
          blownUpIdealExpenses: false,

          annualInvestmentRate: 4,
          maxAnnualInvestmentRate: 20,
          minAnnualInvestmentRate: 0.5,
          blownUpInvestmentRate: false,

          rateStep: 0.5,

          mortgage: false,
          mortgageYearsLeft: 28,
          maxMortgageYearsLeft: 50,
          minMortgageYearsLeft: 0,

          mortgagePaymentFrequency: 12,
          
          mortgagePayment: 3000,
          maxMortgagePayment: 200000,
          minMortgagePayment: 0,

          pension: false,

          pensionAge: '65',
          maxPensionAge: '80',
          minPensionAge: '40',

          pensionIncome: 50000,
          maxPensionIncome: 500000,
          minPensionIncome: 0,

          partnerPension: false,

          partnerAge: '35',
          maxPartnerAge: '80',
          minPartnerAge: '12',

          partnerPensionAge: '65',
          partnerPensionIncome: 50000,

          mortgageAnnualPayment: '',

          annualExpensesRetirement: 0,
          tempExpensesRetirement: 0,

          disposableIncome: '',
          totalExpenses: '',
          totalIncome: '',
          yearsToRetirement: '',
          newInvestments: '',
          totalIncomeRetirement: 0,
          tooMuchExpenses: false,

          yearsToPension: '',
          yearsToPartnerPension: '',

          cashThatYear: '',
          newIncomeThatYear: 0,
          
          idealInvestmentRate: '',
          idealExpenses: '',
          idealRetirementAge: '',
          idealIncome: '',

          formSent: false,
          isDoable: false,
          yearsToRetire: 0,
          maxIterations: 1000,

          // Este es el color de fuente de los valores del eje X
          xAxisTextColor: '#333333',

          chartOptions: {
            // Estos son los colores de las líneas.
            colors: ['#00a9df', '#aaaaaa'],
            chart: {
              id: "vuechart-results",
              zoom: {
                enable: false
              },
              toolbar: {
                show: false
              }
            },
            grid: {
              show: true,
              // Este es el color de las líneas de fondo, los dos ultimos valores son
              // la transparencia.
              borderColor: '#edededFF',
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              }
            },
            tooltip: {
              enabled: true,
              theme: false,
              x: {
                formatter: (value)=>{
                  return (+this.age + +value - +1) + ' Years Old'
                }
              }
            },
            xaxis: {
              categories: [],
              labels: {
                show: true,
                style: {
                  colors: this.xAxisTextColor,
                }
              }      
            },
            yaxis: {
              labels: {
                show: true,
                style: {
                   // Este es el color de fuente de los valores del eje Y
                  colors: '#333333'
                },
                // Esta es la función que le da formato al valor del eje Y.
                formatter: (value) => {
                  return '$' + this.number_format(value)
                }
              }
            },
            markers: {
              size: [3, 0],
              strokeWidth: 0,
              hover: {
                sizeOffset: 4
              }
            }
          },
          chartSeries: [],

      }
  },

  methods:{
      handleSubmit() {

        let newXAxis = {
          categories: [],
          labels: {
            show: true,
            style: {
              colors: this.xAxisTextColor,
            }
          }      
        }

        this.yearsToPension = Number(this.pensionAge) - Number(this.age)
        this.yearsToPartnerPension = Number(this.partnerPensionAge) - Number(this.partnerAge)

        if(this.yearsToPension < 0){
          this.yearsToPension = 0
        }
        if(this.yearsToPartnerPension < 0){
          this.yearsToPartnerPension = 0
        }

        if(this.mortgage){
          this.mortgageAnnualPayment = Number(this.mortgagePayment) * Number(this.mortgagePaymentFrequency)
          this.disposableIncome = Number(this.annualIncome) + Number(this.annualPassiveIncome) - Number(this.annualExpenses) - Number(this.mortgageAnnualPayment)
        }else{
          this.disposableIncome = Number(this.annualIncome) + Number(this.annualPassiveIncome) - Number(this.annualExpenses)
        }

        if(this.pension && this.yearsToPension == 0){
          this.disposableIncome = Number(this.disposableIncome) + Number(this.pensionIncome)
        }

        if(this.partnerPension && this.pension && this.yearsToPartnerPension == 0){
          this.disposableIncome = Number(this.disposableIncome) + Number(this.partnerPensionIncome)
        }

        if(this.disposableIncome >= 0){
          
          this.yearsToRetirement = Number(this.desiredRetirementAge) - Number(this.age)
          this.totalIncomeRetirement = this.getTotalRetirement(this.annualIncome, this.annualInvestmentRate, this.annualExpenses, this.desiredRetirementAge)
   
          if(this.yearsToRetirement < this.mortgageYearsLeft && this.mortgage){
            this.annualExpensesRetirement = Number(this.annualExpenses) + Number(this.mortgageAnnualPayment)
          }else{
            this.annualExpensesRetirement = this.annualExpenses
          }

          let passiveIncomeArray = []
          let expensesArray = []
          
          if(this.totalIncomeRetirement>=this.annualExpensesRetirement){
            this.isDoable = 'yes'
            this.yearsToRetire = Number(this.getIdealRetirementAge()) - Number(this.age)

            newXAxis.categories = []
            for (let $i = 0; $i <= (Number(this.yearsToRetire) + Number(4)); $i++) {
              newXAxis.categories.push(Number(this.age) + Number($i))
            }

            passiveIncomeArray = this.getPassiveIncomeArray(Number(this.yearsToRetire) + Number(4))
            expensesArray = this.getExpensesArray(Number(this.yearsToRetire) + Number(4))
            
          }
          else{
            this.isDoable = 'no'

            this.idealInvestmentRate = this.getIdealInvestmentRate()
            this.idealExpenses = this.getIdealExpenses()
            this.idealRetirementAge = this.getIdealRetirementAge()
            this.idealIncome = this.getIdealIncome()

            newXAxis.categories = []
            for (let $i = this.age; $i <= (Number(this.idealRetirementAge) + Number(4)); $i++) {
              newXAxis.categories.push($i)
            }            

            passiveIncomeArray = this.getPassiveIncomeArray(Number(this.idealRetirementAge) - Number(this.age) + Number(4))
            expensesArray = this.getExpensesArray(Number(this.idealRetirementAge) - Number(this.age) + Number(4))

          }

          this.chartSeries = [
            {
              name: "Passive Income",
              data: passiveIncomeArray,
            },
            {
              name: "Expenses",
              data: expensesArray,
            },
          ]

          this.tooMuchExpenses = false
        }
        else{
          this.tooMuchExpenses = true
          if(this.mortgage){
            this.totalExpenses = Number(this.annualExpenses) + Number(this.mortgageAnnualPayment)
          }else{
            this.totalExpenses = Number(this.annualExpenses)
          }
          this.totalIncome = Number(this.annualIncome) + Number(this.annualPassiveIncome)
        }
        this.chartOptions = {...this.chartOptions, ...{xaxis: newXAxis}}
        this.formSent = true
      },
      getExpensesArray(years){
        let expensesArray = []
        for (let $i = 0; $i <= years; $i++) {
          if ($i <= Number(this.mortgageYearsLeft) && this.mortgage){
            expensesArray.push(Math.round(Number(this.annualExpenses)+Number(this.mortgageAnnualPayment)))
          } else {
            expensesArray.push(Math.round(Number(this.annualExpenses)))
          }
        }
        return expensesArray;
      },
      getPassiveIncomeArray(years){
        let passiveIncomeArray = []
        this.cashThatYear = this.cash
        this.newIncomeThatYear = 0
        let DI = Number(this.annualIncome) + Number(this.annualPassiveIncome) - Number(this.annualExpenses)
        let firstYearPassiveIncome = this.annualPassiveIncome
        let totalIncomeThatYear = 0
        let j = 1
        let k = 1

        if(this.pension && this.yearsToPension == 0){
          firstYearPassiveIncome = Number(firstYearPassiveIncome) + Number(this.pensionIncome)
        }

        if(this.partnerPension && this.pension && this.yearsToPartnerPension == 0){
          firstYearPassiveIncome = Number(firstYearPassiveIncome) + Number(this.partnerPensionIncome)
        }

        passiveIncomeArray.push(Math.round(Number(firstYearPassiveIncome)))


        for (let $i = 1; $i <= years; $i++) {

          this.newIncomeThatYear = (Number(this.cashThatYear) * Number(this.annualInvestmentRate)) / 100
          if ($i <= Number(this.mortgageYearsLeft) && this.mortgage){
            this.cashThatYear = Number(this.cashThatYear) + Number(this.newIncomeThatYear) + Number(DI) - Number(this.mortgageAnnualPayment)
          } else {
            this.cashThatYear = Number(this.cashThatYear) + Number(this.newIncomeThatYear) + Number(DI)
          }

          totalIncomeThatYear = Number(this.newIncomeThatYear) + Number(this.annualPassiveIncome)
          if(this.pension && this.yearsToPension <= j){
            totalIncomeThatYear = Number(totalIncomeThatYear) + Number(this.pensionIncome)
          }
          if(this.partnerPension && this.pension && this.yearsToPartnerPension <= k){
            totalIncomeThatYear = Number(totalIncomeThatYear) + Number(this.partnerPensionIncome)
          }

          passiveIncomeArray.push(Math.round(totalIncomeThatYear))
          
          j++
          k++
        }
        return passiveIncomeArray;
      },
      getTotalRetirement(I,IR,E,RA){
        this.cashThatYear = this.cash
        let YTR = Number(RA) - Number(this.age)
        let DI = Number(I) + Number(this.annualPassiveIncome) - Number(E)
        this.newIncomeThatYear = 0
        let j = 0
        let k = 0

        for (let $i = 0; $i < Number(YTR); $i++) {
          
          this.newIncomeThatYear = (Number(this.cashThatYear) * Number(IR)) / 100
          if ($i < Number(this.mortgageYearsLeft) && this.mortgage){
            this.cashThatYear = Number(this.cashThatYear) + Number(this.newIncomeThatYear) + Number(DI) - Number(this.mortgageAnnualPayment)
          } else {
            this.cashThatYear = Number(this.cashThatYear) + Number(this.newIncomeThatYear) + Number(DI)
          }

          if(this.pension && this.yearsToPension <= j){
            this.cashThatYear = Number(this.cashThatYear) + Number(this.pensionIncome)
          }

          if(this.partnerPension && this.pension && this.yearsToPartnerPension <= k){
            this.cashThatYear = Number(this.cashThatYear) + Number(this.partnerPensionIncome)
          }

          j++
          k++
        }
        return Number(this.newIncomeThatYear) + Number(this.annualPassiveIncome)
      },
      getIdealInvestmentRate() {
        let upperLimit = 1000
        if(this.isDoable === 'yes'){
          //it doesn't need to be calculated if it's doable
          this.blownUpInvestmentRate = false
        }else if(this.getTotalRetirement(this.annualIncome, upperLimit, this.annualExpenses, this.desiredRetirementAge)<this.annualExpensesRetirement){
          this.blownUpInvestmentRate = true
        }else{
          this.blownUpInvestmentRate = false
          let lowerLimit = this.annualInvestmentRate
          for (let i = 0; i < this.maxIterations; i++) {
            let attempt = Math.round(((Number(upperLimit)+Number(lowerLimit))/2 + Number.EPSILON) * 100) / 100

            let attemptResult = this.getTotalRetirement(this.annualIncome, attempt, this.annualExpenses, this.desiredRetirementAge)

            if(Math.trunc(lowerLimit) === Math.trunc(upperLimit)){
              return Math.trunc(attempt)+1
            }
            else if(attemptResult<this.annualExpensesRetirement){
              lowerLimit = attempt
            }
            else{
              upperLimit = attempt
            }
          }
        }
        return false
      },
      getIdealRetirementAge() {

        this.cashThatYear = this.cash
        this.newIncomeThatYear = 0
        let DI = Number(this.annualIncome) + Number(this.annualPassiveIncome) - Number(this.annualExpenses)
        let totalExpenses = 0
        let passiveInvestments = 0
        let maxIterationsAge = Number(120)-Number(this.age)
        let j = 0
        let k = 0

        for (let $i = 0; $i < maxIterationsAge; $i++) {
          if($i > 0){
            this.newIncomeThatYear = (Number(this.cashThatYear) * Number(this.annualInvestmentRate)) / 100
            if ($i <= Number(this.mortgageYearsLeft) && this.mortgage){
              this.cashThatYear = Number(this.cashThatYear) + Number(this.newIncomeThatYear) + Number(DI) - Number(this.mortgageAnnualPayment)
              totalExpenses = Number(this.annualExpenses)+Number(this.mortgageAnnualPayment)
            } else {
              this.cashThatYear = Number(this.cashThatYear) + Number(this.newIncomeThatYear) + Number(DI)
              totalExpenses = Number(this.annualExpenses)
            }
            passiveInvestments = Number(this.newIncomeThatYear) + Number(this.annualPassiveIncome)
          }
          else{
            if ($i <= Number(this.mortgageYearsLeft) && this.mortgage){
              totalExpenses = Number(this.annualExpenses)+Number(this.mortgageAnnualPayment)
            } else {
              totalExpenses = Number(this.annualExpenses)
            }
            passiveInvestments = Number(this.annualPassiveIncome)
          }

          if(this.pension && this.yearsToPension <= j){
            passiveInvestments = Number(passiveInvestments) + Number(this.pensionIncome)
          }

          if(this.partnerPension && this.pension && this.yearsToPartnerPension <= k){
            passiveInvestments = Number(passiveInvestments) + Number(this.partnerPensionIncome)
          }

          if (passiveInvestments > totalExpenses){
            this.blownUpRetirementAge = false
            return Number($i) + Number(this.age)
          }

          j++
          k++
        }
        this.blownUpRetirementAge = true
        return false
      },
      getIdealIncome() {
        let upperLimit = 5000000
        if(this.isDoable === 'yes'){
          //it doesn't need to be calculated if it's doable
          this.blownUpIdealIncome = false
        }else if(this.getTotalRetirement(upperLimit, this.annualInvestmentRate, this.annualExpenses, this.desiredRetirementAge)<this.annualExpensesRetirement){
          this.blownUpIdealIncome = true
        }else{
          this.blownUpIdealIncome = false
          let lowerLimit = this.annualIncome
          for (let i = 0; i < this.maxIterations; i++) {
            
            let attempt = Math.round(((Number(upperLimit)+Number(lowerLimit))/2 + Number.EPSILON) * 100) / 100
            
            let attemptResult = this.getTotalRetirement(attempt, this.annualInvestmentRate, this.annualExpenses, this.desiredRetirementAge)

            if(Math.trunc(lowerLimit) === Math.trunc(upperLimit)){
              return Math.trunc(attempt)+1
            }
            else if(attemptResult<this.annualExpensesRetirement){
              lowerLimit = attempt
            }
            else{
              upperLimit = attempt
            }
          }
        }
        return false
      },
      getIdealExpenses() {
        let attemptResult = this.getTotalRetirement(this.annualIncome, this.annualInvestmentRate, 0, this.desiredRetirementAge)
        if(this.isDoable === 'yes'){
          //it doesn't need to be calculated if it's doable
          this.blownUpIdealExpenses = false
        }else if(attemptResult<this.annualExpensesRetirement){
          this.blownUpIdealExpenses = true
        }else{
          this.blownUpIdealExpenses = false
          let lowerLimit = 0
          let upperLimit = this.annualExpenses
          for (let i = 0; i < this.maxIterations; i++) {
            
            let attempt = Math.round(((Number(upperLimit)+Number(lowerLimit))/2 + Number.EPSILON) * 100) / 100
            
            attemptResult = this.getTotalRetirement(this.annualIncome, this.annualInvestmentRate, attempt, this.desiredRetirementAge)

            if(this.yearsToRetirement < this.mortgageYearsLeft && this.mortgage){
              this.tempExpensesRetirement = Number(attempt) + Number(this.mortgageAnnualPayment)
            }else{
              this.tempExpensesRetirement = attempt
            }
          
            if(Math.trunc(lowerLimit) === Math.trunc(upperLimit)){
              return Math.trunc(attempt)
            }
            else if(attemptResult<this.tempExpensesRetirement){
              upperLimit = attempt
            }
            else{
              lowerLimit = attempt
            }
          }
        }
        return false
      },
      ageLimit() {
        if (this.age > this.maxAge){
          this.age = this.maxAge
        } else if (this.age < this.minAge){
          this.age = this.minAge
        }
        this.minDesiredRetirementAge = Number(this.age) + Number(1)
        if (this.age >= this.desiredRetirementAge){
          this.desiredRetirementAge = Number(this.age) + Number(1)
        }
      },
      desiredRetirementAgeAdjustment(){
        this.minDesiredRetirementAge = Number(this.age) + Number(1)
        if (this.age >= this.desiredRetirementAge){
          this.desiredRetirementAge = Number(this.age) + Number(1)
        }
      },
      desiredRetirementAgeLimit() {
        if (this.desiredRetirementAge > this.maxDesiredRetirementAge) {
          this.desiredRetirementAge = this.maxDesiredRetirementAge
        } else if (this.desiredRetirementAge < this.minDesiredRetirementAge) {
          this.desiredRetirementAge = this.minDesiredRetirementAge
        }
      },
      cashLimit() {
        if (this.cash > this.maxCash) {
          this.cash = this.maxCash
        } else if (this.cash < this.minCash) {
          this.cash = this.minCash
        }
      },
      annualIncomeLimit() {
        if (this.annualIncome > this.maxAnnualIncome) {
          this.annualIncome = this.maxAnnualIncome
        } else if (this.annualIncome < this.minAnnualIncome) {
          this.annualIncome = this.minAnnualIncome
        }
      },
      annualPassiveIncomeLimit() {
        if (this.annualPassiveIncome > this.maxAnnualPassiveIncome) {
          this.annualPassiveIncome = this.maxAnnualPassiveIncome
        } else if (this.annualPassiveIncome < this.minAnnualPassiveIncome) {
          this.annualPassiveIncome = this.minAnnualPassiveIncome
        }
      },
      annualExpensesLimit() {
        if (this.annualExpenses > this.maxAnnualExpenses) {
          this.annualExpenses = this.maxAnnualExpenses
        } else if (this.annualExpenses < this.minAnnualExpenses) {
          this.annualExpenses = this.minAnnualExpenses
        }
      },
      annualInvestmentRateLimit() {
        if (this.annualInvestmentRate > this.maxAnnualInvestmentRate) {
          this.annualInvestmentRate = this.maxAnnualInvestmentRate
        } else if (this.annualInvestmentRate < this.minAnnualInvestmentRate) {
          this.annualInvestmentRate = this.minAnnualInvestmentRate
        }
      },
      mortgageYearsLeftLimit() {
        if (this.mortgageYearsLeft > this.maxMortgageYearsLeft) {
          this.mortgageYearsLeft = this.maxMortgageYearsLeft
        } else if (this.mortgageYearsLeft < this.minMortgageYearsLeft) {
          this.mortgageYearsLeft = this.minMortgageYearsLeft
        }
      },
      mortgageAnnualPaymentLimit() {
        if (this.mortgageAnnualPayment > this.maxMortgageAnnualPayment) {
          this.mortgageAnnualPayment = this.maxMortgageAnnualPayment
        } else if (this.mortgageAnnualPayment < this.minMortgageAnnualPayment) {
          this.mortgageAnnualPayment = this.minMortgageAnnualPayment
        }
      },
      pensionAgeLimit() {
        if (this.pensionAge > this.maxPensionAge) {
          this.pensionAge = this.maxPensionAge
        } else if (this.pensionAge < this.minPensionAge) {
          this.pensionAge = this.minPensionAge
        }
      },
      pensionIncomeLimit() {
        if (this.pensionIncome > this.maxPensionIncome) {
          this.pensionIncome = this.maxPensionIncome
        } else if (this.pensionIncome < this.minPensionIncome) {
          this.pensionIncome = this.minPensionIncome
        }
      },
      partnerAgeLimit() {
        if (this.partnerAge > this.maxPartnerAge) {
          this.partnerAge = this.maxPartnerAge
        } else if (this.partnerAge < this.minPartnerAge) {
          this.partnerAge = this.minPartnerAge
        }
      },
      partnerPensionAgeLimit() {
        if (this.partnerPensionAge > this.maxPensionAge) {
          this.partnerPensionAge = this.maxPensionAge
        } else if (this.partnerPensionAge < this.minPensionAge) {
          this.partnerPensionAge = this.minPensionAge
        }
      },
      partnerPensionIncomeLimit() {
        if (this.partnerPensionIncome > this.maxPensionIncome) {
          this.partnerPensionIncome = this.maxPensionIncome
        } else if (this.partnerPensionIncome < this.minPensionIncome) {
          this.partnerPensionIncome = this.minPensionIncome
        }
      },
      // Util Functions
      number_format: function number_format (number, decimals, dec_point, thousands_sep) {
          // Strip all characters but numerical ones.
          number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
          var n = !isFinite(+number) ? 0 : +number,
              prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
              sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
              dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
              s = '',
              toFixedFix = function (n, prec) {
                  var k = Math.pow(10, prec);
                  return '' + Math.round(n * k) / k;
              };
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
          if (s[0].length > 3) {
              s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
          }
          if ((s[1] || '').length < prec) {
              s[1] = s[1] || '';
              s[1] += new Array(prec - s[1].length + 1).join('0');
          }
          return s.join(dec);
      },
      currency_format: function (t, n = 2, e = '.', r = ',', f = '$', g) {
        let i = this.number_format(t, n, e, r); return "string" == typeof f && f.length > 0 && (i = f + i), "string" == typeof g && g.length > 0 && (i += g), i
      },
      percentage_format: function(t, n = 2, e = '.', r = ',', f = '%'){
        let i = this.number_format(t, n, e, r); return ("string" == typeof f && f.length > 0 && (i = i + f), i)
      }
  }  
}
</script>

<style>
</style>
